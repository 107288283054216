/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Self host fonts using Fontsource https://github.com/fontsource/fontsource
import 'fontsource-poppins/latin-400.css';
import 'fontsource-poppins/latin-700.css';
import 'fontsource-poppins/latin-800.css';
import 'fontsource-poppins/latin-900.css';

import './src/css/tailwind.css';
